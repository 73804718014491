import { IErrorDto, IResponseModelEnvelope } from './';

export interface ICityModel {
  name: string;
  externalId: string;
  created: string;
  updated: string;
  latitude: number;
  longitude: number;
}

export interface ICitiesModel {
  name: string;
  externalId: string;
  created: string;
  updated: string;
}


export class isValidIdModel {
  isValid: boolean;
}

export class CitiesModel implements ICitiesModel {
  name: string;
  externalId!: string;
  created: string;
  updated: string;

  constructor(data?: ICitiesModel) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}

export class CitiesResponseModelEnvelope implements IResponseModelEnvelope<CitiesModel[]> {
  data?: CitiesModel[];
  errors?: IErrorDto[] | null;
  readonly hasErrors?: boolean;

  constructor(data?: IResponseModelEnvelope<CitiesModel[]>) {
    if (!data) {
      return;
    }
    for (const property in data) {
      if (data.hasOwnProperty(property)) {
        (this)[property] = (data)[property];
      }
    }
  }
}
